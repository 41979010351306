<template>
  <Popover placement="top" @sureHandler="del(data.id, $event)" class="left">
    <template #tip>
      <p v-if="data.parentId">删除此子分类</p>
      <p v-else>如果该分类存在子分类，请先删除子分类，再进行此操作！</p>
    </template>
    <template #reference="{ scope: loading }">
      <color-text-btn class="update-btn" :loading="loading" size="mini" type="danger">删除</color-text-btn>
    </template>
  </Popover>
</template>

<script>
import { delMixin } from '@/mixins'
import { del as delApi } from '@/api/imageTypeApi'
export default {
  mixins: [delMixin],
  props: {
    data: {
      type: Object,
      required: true
    },
    sup_this: {
      type: Object,
      required: true
    }
  },
  methods: {
    delApi,
    init() {
      this.sup_this && this.sup_this.init()
    }
  }
}
</script>

<style scoped lang="scss">
.left {
  margin-right: 35px !important;
}
</style>
